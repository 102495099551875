import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage } from "../../redux/reducers/websocketState";

const Star = ({ email }) => {
  const dispatch = useDispatch();
  const messageId = email.messageId || email.messageid;

  const isStarred = useSelector(
    (state) =>
      state.emailsState.EmailsInMainPage.find((e) => e.messageid === messageId)
        ?.isstarred
  );

  const [starred, setStarred] = useState(isStarred);

  useEffect(() => {
    setStarred(isStarred);
  }, [isStarred]);

  const handleStar = () => {
    const message = {
      operation: "UPDATE_STAR",
      data: { messageId },
    };
    dispatch(sendMessage(message));
  };

  return (
    <span className="star-checkbox" onClick={(e) => e.stopPropagation()}>
      <input
        type="checkbox"
        id={`star-email-${messageId}`}
        className="star-checkbox-input"
        checked={starred}
        onChange={handleStar}
        style={{ display: "none" }}
      />
      <label
        htmlFor={`star-email-${messageId}`}
        className={starred ? "star-checkbox-label-gold" : "star-checkbox-label"}
      >
        {starred ? "★" : "☆"}
      </label>
    </span>
  );
};

export default Star;
