export const setItemToLocalStorage = (
  key,
  value,
  tte = 6 * 60 * 60 * 1000
) => {
  let now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + tte,
  };
  if (typeof window !== "undefined") {
    localStorage.setItem(key, JSON.stringify(item));
  }
};

export const getItemFromLocalStorage = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

export const firstLetterCapital =(str)=>{
  return str.charAt(0).toUpperCase() + str.slice(1);
}
