import { createSlice } from "@reduxjs/toolkit";
import { resetForm, updateField } from "./ContactState";
//import { persistor } from "../store";
import {
  setFormSubmitMessageApi,
  setMessageingThreadErr,
} from "./GlobalErrorState";
import { userSignInStatusChange } from "./secretState";

const initialState = {
  _id: "",
  fullName: "",
  phoneNumber: "",
  normalizedEmail: "",
  messageingThread: [],
  role: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUserForm: () => {
      return initialState;
    },
    setUser: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    updateUserField: (state, action) => {
      const { field, value } = action.payload;
      if (field in state) {
        return {
          ...state,
          [field]: value,
        };
      }
      return state;
    },
    addMessage: (state, action) => {
      state.messageingThread.push(action.payload);
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
  },
});

export const {
  setUser,
  updateUser,
  addMessage,
  updateUserField,
  resetUserForm,
  setRole,
} = userSlice.actions;

export const signInWithGoogle = () => async (dispatch) => {
  try {
    const currentURL = window.location.pathname;
    localStorage.setItem("postLoginRedirect", currentURL);
    window.location.href = `${process.env.REACT_APP_API_BaseURl}/auth/google`;
  } catch (error) {
    console.log("Error in fetch",error);
  }
};

export const signInWithLinkedIn = () => async (dispatch) => {
  try {
    const currentURL = window.location.pathname;
    localStorage.setItem("postLoginRedirect", currentURL);
    window.location.href = `${process.env.REACT_APP_API_BaseURl}/auth/linkedin`;
  } catch (error) {
    console.log("Error in fetch",error);
  }
};

export const signInWithOutLook = () => async (dispatch) => {
  try {
    const currentURL = window.location.pathname;
    localStorage.setItem("postLoginRedirect", currentURL);

    
    window.location.href = process.env.REACT_APP_API_BaseURl + "/auth/outlook";
  } catch (error) {
    console.log("Error in fetch");
  }
};

export const cleanStorage = () => async (dispatch) => {
  try {
    dispatch(resetForm());
    dispatch(resetUserForm());
    //await persistor.purge(["user", "contact"]);
    localStorage.removeItem("persist:root");
  } catch (error) {
    console.log("Error in cleanStorage:", error);
  }
};

export const getMesagesByUser = () => async (dispatch) => {
  try {
    const res = await fetch(
      process.env.REACT_APP_API_BaseURl + "/Messages/getMesagesByUser",
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const userData = await res.json();
    if (userData.success) {
      await dispatch(
        updateUserField({
          field: "messageingThread",
          value: userData.messageingThread,
        })
      );
    } else {
      dispatch(setMessageingThreadErr(userData.err));
    }

    return true;
  } catch (err) {
    console.error(err);
  }
};
export const handleGetUserData = (token) => async (dispatch) => {
  try {
    let headers = {
      "Content-Type": "application/json",
    };

    if (token) {
      headers.Authorization = `Bearer ${token.replace(":", "")}`;
    }

    const res = await fetch(
      `${process.env.REACT_APP_API_BaseURl}/auth/getUserData`,
      {
        method: "GET",
        credentials: "include",
        headers,
      }
    );

    const userData = await res.json();
    for (const key in userData.user) {
      const value = userData.user[key];
      dispatch(updateUserField({ field: key, value }));
      dispatch(updateField({ field: key, value }));
    }
    return true;
  } catch (err) {
    console.error(err);
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    const logingOutApi = await fetch(
      process.env.REACT_APP_API_BaseURl + "/auth/google/gooleLogOut",
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (logingOutApi.ok) {
      dispatch(resetForm());
      dispatch(resetUserForm());
      dispatch(setFormSubmitMessageApi(""));
      //await persistor.purge(["user", "contact"]);
      localStorage.removeItem("persist:root");
      dispatch(userSignInStatusChange(false));
      return true;
    } else {
      return false;
    }
  } catch (err) {
    console.log("Error in fetch");
    return false;
  }
};

export default userSlice.reducer;
